import React from "react";
import '../sass/homeNews.css';

import CustomH2 from "./technicalComponents/CustomH2";
import CustomArticle from "./technicalComponents/CustomArticle";

import CustomButton from "./technicalComponents/CustomButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import article17 from "../img/articles/article17.webp";
import article18 from "../img/articles/article18.webp";
import article19 from "../img/articles/article19.webp";

const HomeNews = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/news');
    }
    return(
        <section className="newsSection">
            <CustomH2 text="News"/>
            <div className="newsSection-grid">
                <CustomArticle
                    date="2024-11-10"
                    title={t("art_19_title")}
                    image={article19}
                    isLarge={true}
                    linkEN="https://mailchi.mp/94238edc70d8/maciej-gadysz-claims-third-place-in-spanish-formula-4-championship?e=9eec90125b"
                    linkPL="https://mailchi.mp/6448f350a30e/maciej-gadysz-drugim-wicemistrzem-hiszpaskiej-formuy-4?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-11-07"
                    title={t("art_18_title")}
                    image={article18}
                    isLarge={false}
                    linkEN="https://mailchi.mp/43fa3a283617/maciej-gadysz-ready-for-the-f4-title-showdown?e=9eec90125b"
                    linkPL="https://mailchi.mp/a4e91e2e25c3/maciej-gadysz-gotowy-na-decydujce-starcie-w-formule-4?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-10-06"
                    title={t("art_17_title")}
                    image={article17}
                    isLarge={false}
                    linkEN="https://mailchi.mp/577a58674629/maciej-gadysz-twice-on-the-podium-in-jerez?e=9eec90125b"
                    linkPL="https://mailchi.mp/22c3932aae54/maciej-gadysz-dwukrotnie-na-podium-w-jerez?e=9eec90125b"
                />
            </div>
            <div className="buttonContainer">
                <CustomButton label={t("button_01")} onClick={handleClick}/>
            </div>
        </section>
    );
};

export default HomeNews;